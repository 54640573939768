<template>
  <div class="view-head">
    <h1 class="title">
      {{ headerTitle }}
    </h1>
    <div class="buttons-section">
      <VasionButton
        classProp="secondary"
        @vasionButtonClicked="cancel"
      >
        CANCEL
      </VasionButton>
      <VasionButton
        classProp="primary"
        class="mr-0"
        :isDisabled="!saveActive"
        @vasionButtonClicked="save"
      >
        SAVE
      </VasionButton>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PackageHeader',
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    saveAvailable: {
      type: Boolean,
      required: true,
      default: false,
    },
    cancel: {
      type: Function,
      required: true,
      default: () => { },
    },
    save: {
      type: Function,
      required: true,
      default: () => { },
    },
  },
  data: function () {
    return {
      headerTitle: this.title,
      saveActive: this.saveAvailable,
    }
  },
  watch: {
    title(newTitle) {
      this.headerTitle = newTitle
    },
    saveAvailable(newSaveAvailable) {
      this.saveActive = newSaveAvailable
    },
  },
}
</script>
<style lang="scss" scoped>
.view-head {
  min-height: 56px;
  max-height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFFFFF;
  padding-left: 16px;
  padding-right: 16px;
  -webkit-box-shadow: 0 1px 0 0 #e4e5e6;
  box-shadow: 0 1px 0 0 #e4e5e6;

  h1.title {
    font-family: Archivo;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #161718;
    margin: 0;
  }
}

::v-deep .mr-0 button {
  margin-right: 0px;
}
</style>
