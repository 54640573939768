<template>
  <div class="container">
    <Loading
      :active.sync="isLoading"
      :background-color="loaderBackgroundColor"
      :color="loaderColor"
      :is-full-page="false"
      :opacity="1"
      loader="dots"
    />
    <div>
      <md-radio v-model="fieldsData.autoSubmit" :value="false" @change="submitTypeUpdate">
        Manually Submit Packages
      </md-radio>
      <md-radio v-model="fieldsData.autoSubmit" :value="true" @change="submitTypeUpdate">
        Auto Submit Packages
      </md-radio>
    </div> 
    <VasionCheckbox
      id="merge-package-documents"
      name="mergeDocuments"
      class="checkbox-labels"
      :checked="mergeDocuments"
      @value="updateCheckbox($event, 'mergeDocuments')"
    >
      Merge Documents
    </VasionCheckbox>
    <div v-if="mergeDocuments" class="merge-config-container">
      <p>Merge Order</p>
      <div class="table-responsive">
        <table class="table-details">
          <tbody>
            <tr v-for="(item, index) in packageFieldValues" :key="index">
              <td>
                {{ item.Value }}
              </td>
              <td v-if="!item.DoNotMerge">
                <VasionButton
                  :title="index === 0 ? '' : 'Move up'"
                  icon="VasionArrowLargeUpward"
                  :isDense="true"
                  :isDisabled="index === 0"
                  @vasionButtonClicked="reorderItem('up', item)"
                />
                <VasionButton
                  :title="index+1 === packageFieldValues.length ? '' : 'Move down'"
                  icon="VasionArrowLargeDownward"
                  :isDense="true"
                  :isDisabled="index+1 === packageFieldValues.length"
                  @vasionButtonClicked="reorderItem('down', item)"
                />
                <VasionButton
                  title="Remove Field"
                  icon="VasionDeleteIcon"
                  :isDense="true"
                  @vasionButtonClicked="preventDeletion(item)"
                />
              </td>
              <td v-else>
                <VasionButton
                  title="Add field to the Merge"
                  icon="VasionAddIcon"
                  :isDense="true"
                  @vasionButtonClicked="addItemToMerge(item)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="destination">
        <VasionDropList
          v-slot="slotItem"
          v-model="mergeLocationType"
          displayName="name"
          placeholder="Select Location..."
          title="Merged Package Destination"
          type="plain-list"
          valueName="value"
          width="200"
          :closeOnOutsideClick="true"
          :dataArray="destinationOptionsData"
          :overlayList="true"
          @input="locationChange($event, 'merge')"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
        <VasionInput
          v-if="mergeLocationType.value === 'storage'"
          v-model="mergeFolderName"
          class="bottom-align"
          title=""
          inputType="top-white"
          name="mergeFolderName"
          placeholder="Select Folder..."
          :width="'300'"
          :isDisabled="true"
        />
        <VasionButton
          v-if="mergeLocationType.value === 'storage'"
          id="merge-browse-folder-btn"
          :classProp="'primary'"
          @vasionButtonClicked="folderSelectionType = 'merge'; showFileUploader = true;"
        >
          Browse
        </VasionButton>
        <VasionDropList
          v-if="mergeLocationType.value === 'workflow'"
          v-slot="slotItem"
          v-model="mergeWorkflow"
          width="400"
          title=""
          class="vasion-down-20"
          placeholder="Select Workflow..."
          type="plain-list"
          displayName="name"
          valueName="value"
          :closeOnOutsideClick="true"
          :dataArray="workflowList"
          :overlayList="true"
          @input="updateWorkflow($event, 'merge');"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>
    </div>
    <VasionCheckbox
      id="move-base-documents"
      name="movePackage"
      class="checkbox-labels"
      :checked="movePackage"
      @value="updateCheckbox($event, 'movePackage')"
    >
      Move Original Documents
    </VasionCheckbox>
    <div v-if="movePackage" class="move-config-container">
      <div class="destination">
        <VasionDropList
          v-slot="slotItem"
          v-model="moveLocationType"
          displayName="name"
          placeholder="Select Location..."
          title=""
          type="plain-list"
          valueName="value"
          width="200"
          :closeOnOutsideClick="true"
          :dataArray="destinationOptionsData"
          :overlayList="true"
          @input="locationChange($event, 'move')"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
        <VasionInput
          v-if="moveLocationType.value === 'storage'"
          v-model="moveFolderName"
          title=""
          inputType="top-white"
          name="moveFolderName"
          placeholder="Select Folder..."
          :width="'300'"
          :isDisabled="true"
        />
        <VasionButton
          v-if="moveLocationType.value === 'storage'"
          id="move-browse-folder-btn"
          :classProp="'primary'"
          class=""
          @vasionButtonClicked="folderSelectionType = 'move'; showFileUploader = true;"
        >
          Browse
        </VasionButton>
        <VasionDropList
          v-if="moveLocationType.value === 'workflow'"
          v-slot="slotItem"
          v-model="moveWorkflow"
          width="400"
          title=""
          placeholder="Select Workflow..."
          type="plain-list"
          displayName="name"
          valueName="value"
          :closeOnOutsideClick="true"
          :dataArray="workflowList"
          :overlayList="true"
          @input="updateWorkflow($event, 'move');"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>
    </div>
    <md-dialog
      id="modal-select-destination"
      :md-click-outside-to-close="false"
      :md-active.sync="showFileUploader"
    >
      <StartWorkflowFileUpload
        headerText="Select Destination"
        :isWorkflow="false"
        :folderSelectionMode="true"
        :showBackButton="false"
        :handleFileInTheParent="true"
        :showDirectScan="false"
        @cancelButtonClick="showFileUploader = false"
        @continueToAttributeForm="continueFromFolderSelection"
      />
    </md-dialog>
    <md-dialog
      id="modal-delete-field-prevention"
      :md-active.sync="showPreventFieldDeletionModal"
      :md-click-outside-to-close="false"
    >
      <h1 class="deletion-modal-title">
        Remove "{{ itemToDelete.Value }}"?
      </h1>
      <VasionConfirmationDialog
        :message="`Are you sure you want to remove the ${itemToDelete.Value} from the merge package?`"
        @noButtonClick="cancelDeletion"
        @yesButtonClick="deleteItem"
      />
    </md-dialog>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay';
import StartWorkflowFileUpload from '@/components/workflow/StartWorkflowFileUpload.vue'
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'PackageTabDetails',
  components: {
    Loading,
    StartWorkflowFileUpload,
  },
  data: function () {
    return {
      // Data To Export
      fieldsData: {
        autoSubmit: false,
        mergeDocuments: false,
        mergeFolder: 0,
        mergeWorkflow: 0,
        movePackage: false,
        moveFolder: 0,
        moveWorkflow: 0,
      },
      // Working Data
      destinationOptionsData: [],
      folderSelectionType: '',
      isLoading: false,
      itemToDelete: {},
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      mergeDocuments: false,
      mergeLocationType: {},
      mergeFolderName: '',
      mergeWorkflow: {},
      moveFolderName: '',
      movePackage: false,
      moveLocationType: {},
      moveWorkflow: {},
      packageFieldValues: [],
      showFileUploader: false,
      showPreventFieldDeletionModal: false,
      workflowList: [],
    }
  },
  computed: {
    allWorkflowList() { return this.$store.state.workflow.workflowAdminList },
    pkgFields() { return this.$store.state.forms.pkgFields },
    pkgFieldValues() { return this.$store.state.forms.pkgFields.packageFieldValues },
    pkgToEdit() { return this.$store.state.forms.pkgToEdit },
  },
  watch: {
    pkgFieldValues: async function (newPkgFields) {
      if (!this.pkgFields?.packageFieldValues) return
      this.packageFieldValues = cloneDeep(newPkgFields).sort((a, b) => a.MergeOrder - b.MergeOrder)
    },
  },
  async created() {
    this.isLoading = true
    await this.initializeData()
    await this.setValuesToEdit(this.pkgToEdit)
    this.isLoading = false
  },
  methods: {
    async addItemToMerge(item) {
      if (!item?.Value) return
      const arrayName = 'packageFieldValues'
      const objProperty = 'Value'
      const propertyForOrder = 'MergeOrder'

      const originalIndex = this[arrayName].findIndex((f) => f[objProperty] === item[objProperty])
      const firstDoNotMerge = this[arrayName].findIndex((f) => f.DoNotMerge === true)
      const newIndex = firstDoNotMerge >= 0 ? firstDoNotMerge : this[arrayName].length
      this[arrayName][originalIndex].DoNotMerge = false
      const tempField = this[arrayName][originalIndex]

      this[arrayName].splice(originalIndex, 1)
      this[arrayName].splice(newIndex, 0, tempField)

      const newPackageFieldValues = this[arrayName].map((field, index) => {
        field[propertyForOrder] = index
        return { ...field }
      })
      await this.$store.dispatch('forms/setPkgFields', { ...this.pkgFields, packageFieldValues: newPackageFieldValues })

      this.emitDirty()
    },
    cancelDeletion() {
      this.itemToDelete = {}
      this.showPreventFieldDeletionModal = false
    },
    async continueFromFolderSelection(file) {
      this.showFileUploader = false
      this[`${this.folderSelectionType}FolderName`] = file.name
      this.fieldsData[`${this.folderSelectionType}Folder`] = file.value

      this.updateStore()
      this.emitDirty()
    },
    async deleteItem() {
      const arrayName = 'packageFieldValues'
      const objProperty = 'Value'
      const propertyForOrder = 'MergeOrder'

      const originalIndex = this[arrayName].findIndex((f) => f[objProperty] === this.itemToDelete[objProperty])

      this[arrayName][originalIndex].DoNotMerge = true
      const tempField = this[arrayName][originalIndex]
      this[arrayName].splice(originalIndex, 1)
      this[arrayName].push(tempField)

      const newPackageFieldValues = this[arrayName].map((field, index) => {
        field[propertyForOrder] = index
        return { ...field }
      })
      await this.$store.dispatch('forms/setPkgFields', { ...this.pkgFields, packageFieldValues: newPackageFieldValues })

      this.itemToDelete = {}
      this.showPreventFieldDeletionModal = false

      this.emitDirty()
    },
    emitDirty() {
      this.$emit('emitDirty')
    },
    async initializeData() {
      this.destinationOptionsData = [
        {
          name: 'Storage',
          value: 'storage',
        },
        {
          name: 'Workflow',
          value: 'workflow',
        },
      ]

      await this.$store.dispatch('workflow/getWorkflowAdminList')
      this.workflowList = await this.allWorkflowList.map((workflow) => {
        return {
          name: workflow.sName,
          value: workflow.iID,
        }
      })

      if (!this.pkgFields?.packageFieldValues) return
      this.packageFieldValues = cloneDeep(this.pkgFields.packageFieldValues).sort((a, b) => a.MergeOrder - b.MergeOrder)
    },
    locationChange(event, type) {
      this[`${type}FolderName`] = ''
      this.fieldsData[`${type}Folder`] = 0
      this[`${type}Workflow`] = {}
      this.fieldsData[`${type}Workflow`] = 0

      this.updateStore()
      this.emitDirty()
    },
    preventDeletion(item) {
      if (!item?.Value) return
      this.itemToDelete = item
      this.showPreventFieldDeletionModal = true
    },
    async reorderItem(direction, item) {
      const arrayName = 'packageFieldValues'
      const objProperty = 'Value'
      const propertyForOrder = 'MergeOrder'

      const currentIndex = this[arrayName].findIndex((f) => f[objProperty] === item[objProperty])
      const newIndex = (direction === 'up') ? (currentIndex - 1) : (currentIndex + 1)

      if (newIndex < 0 || newIndex == this[arrayName].length) return

      const indexes = [currentIndex, newIndex].sort((a, b) => a - b)
      this[arrayName].splice(indexes[0], 2, this[arrayName][indexes[1]], this[arrayName][indexes[0]])

      const newPackageFieldValues = this[arrayName].map((field, index) => {
        field[propertyForOrder] = index
        return { ...field }
      })
      await this.$store.dispatch('forms/setPkgFields', { ...this.pkgFields, packageFieldValues: newPackageFieldValues })

      this.emitDirty()
    },
    async setValuesToEdit(pkg) {
      if (Object.keys(pkg).length === 0) return
      // AutoSubmit
      this.fieldsData.autoSubmit = pkg.AutoSubmit
      // Checkboxes
      if ('MovePackage' in pkg) {
        this.movePackage = pkg.MovePackage
        this.fieldsData.movePackage = pkg.MovePackage
      }
      if ('MergeDocuments' in pkg) {
        this.mergeDocuments = pkg.MergeDocuments
        this.fieldsData.mergeDocuments = pkg.MergeDocuments
      }
      // Merge Location Select
      const mergeLocationFlag = pkg.MergeWorkflow ? 'Workflow' : (pkg.MergeFolder ? 'Storage' : '')
      const mergeLocationType = await this.destinationOptionsData.filter((destination) => {
        return destination.name === mergeLocationFlag
      })
      if (mergeLocationType.length > 0) this.mergeLocationType = mergeLocationType[0]
      // Merge Workflow
      if (pkg.MergeWorkflow) {
        const mergeWorkflow = await this.workflowList.filter((workflow) => {
          return pkg.MergeWorkflow === workflow.value
        })
        if (mergeWorkflow.length > 0) this.mergeWorkflow = mergeWorkflow[0]
        if (mergeWorkflow.length > 0) this.fieldsData.mergeWorkflow = mergeWorkflow[0].value
      }
      // Merge Folder
      if (pkg.MergeFolder) {
        const mergeFolder = await this.$store.dispatch('vault/getSingleVault', pkg.MergeFolder)
        if (mergeFolder) {
          this.mergeFolderName = mergeFolder.vaultName
          this.fieldsData.mergeFolder = mergeFolder.vaultID
        }
      }
      // Move Location Select
      const moveLocationFlag = pkg.MoveWorkflow ? 'Workflow' : (pkg.MoveFolder ? 'Storage' : '')
      const moveLocationType = await this.destinationOptionsData.filter((destination) => {
        return destination.name === moveLocationFlag
      })
      if (moveLocationType.length > 0) this.moveLocationType = moveLocationType[0]
      // Move Workflow
      if (pkg.MoveWorkflow) {
        const moveWorkflow = await this.workflowList.filter((workflow) => {
          return pkg.MoveWorkflow === workflow.value
        })
        if (moveWorkflow.length > 0) this.moveWorkflow = moveWorkflow[0]
        if (moveWorkflow.length > 0) this.fieldsData.moveWorkflow = moveWorkflow[0].value
      }
      // Move Folder
      if (pkg.MoveFolder) {
        const moveFolder = await this.$store.dispatch('vault/getSingleVault', pkg.MoveFolder)
        if (moveFolder) {
          this.moveFolderName = moveFolder.vaultName
          this.fieldsData.moveFolder = moveFolder.vaultID
        }
      }
    },
    submitTypeUpdate() {
      this.updateStore()
      this.emitDirty()
    },
    updateCheckbox(currentValue, variable) {
      this[variable] = currentValue
      this.fieldsData[variable] = currentValue
      if (variable === 'mergeDocuments') {
        this.mergeFolderName = ''
        this.fieldsData.mergeFolder = 0
        this.mergeWorkflow = {}
        this.fieldsData.mergeWorkflow = 0
      }
      if (variable === 'movePackage') {
        this.moveFolderName = ''
        this.fieldsData.moveFolder = 0
        this.moveWorkflow = {}
        this.fieldsData.moveWorkflow = 0
      }
      this.updateStore()
      this.emitDirty()
    },
    async updateStore() {
      await this.$store.dispatch('forms/setPkgDetails', this.fieldsData)
    },
    updateWorkflow(currentValue, variable) {
      this.fieldsData[`${variable}Workflow`] = currentValue.value
      this.updateStore()
      this.emitDirty()
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/vasionStyles.scss';

::v-deep .checkbox-labels label {
  color: unset;
}

::v-deep .checkbox-labels svg {
  margin-right: 8px;
}

.merge-config-container {
  padding-top: 16px;
}

.merge-config-container,
.move-config-container {
  .destination {
    padding-top: 16px;
    padding-bottom: 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}

.merge-config-container ::v-deep .input-group,
.move-config-container ::v-deep .input-group {
  height: unset;
}

.merge-config-container ::v-deep input,
.move-config-container ::v-deep input {
  height: 36px;
}

.move-config-container ::v-deep button {
  margin-top: 0px;
}

.merge-config-container .bottom-align ::v-deep .input-group,
.merge-config-container ::v-deep .vasion-button {
  margin-top: auto;
  margin-bottom: 0px;
}

.merge-config-container ::v-deep button {
  margin-bottom: 1px;
}

table.table-details {
  margin-left: 16px;

  tbody>tr>td:nth-child(1) {
    width: 450px;
  }

  tbody>tr>td:nth-child(2) {
    width: 160px;
    min-width: 160px;
    text-align: right;
    text-align: end;
  }

  td {
    border-bottom: 1px solid #CBC4CF;
    padding: 10px 4px;
  }
}

.deletion-modal-title {
  font-family: "Archivo Medium";
  font-weight: 500;
  line-height: 1.13;
  text-align: left;
  font-size: 1.75em;
  white-space: pre-wrap;
  margin-top: 16px;
  margin-left: 16px;
  margin-bottom: 0px;
}

// md: 768px
@media (min-width: $breakpoint-md) {

  .merge-config-container,
  .move-config-container {
    .destination {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .merge-config-container ::v-deep .vasion-droplist,
  .move-config-container ::v-deep .vasion-droplist {
    width: unset;
  }
}
</style>
