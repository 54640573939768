<template>
  <div class="view-body">
    <div class="tab-container">
      <div class="tab-buttons">
        <VasionButton
          id="tab-button-fields"
          :classProp="fieldsTabClass"
          @vasionButtonClicked="updateActiveTab('fields')"
        >
          Fields
        </VasionButton>
        <VasionButton
          id="tab-button-rules"
          :classProp="rulesTabClass"
          @vasionButtonClicked="updateActiveTab('rules')"
        >
          Rules
        </VasionButton>
        <VasionButton
          id="tab-button-details"
          :classProp="detailsTabClass"
          @vasionButtonClicked="updateActiveTab('details')"
        >
          Submission Details
        </VasionButton>
      </div>
      <div class="tab-content">
        <KeepAlive>
          <PackageTabFields v-if="activeTab === 'fields'" @emitDirty="emitDirty" />
          <PackageTabRules v-if="activeTab === 'rules'" @emitDirty="emitDirty" />
          <PackageTabDetails v-if="activeTab === 'details'" @emitDirty="emitDirty" />
        </KeepAlive>
      </div>
    </div>
  </div>
</template>
<script>
import PackageTabFields from '@/components/forms/package/PackageTabFields.vue';
import PackageTabRules from '@/components/forms/package/PackageTabRules.vue';
import PackageTabDetails from '@/components/forms/package/PackageTabDetails.vue';

export default {
  name: 'PackageBody',
  components: {
    PackageTabFields,
    PackageTabRules,
    PackageTabDetails,
  },
  data: function () {
    return {
      activeTab: 'fields',
      tabs: ['fields', 'rules', 'details'],
    }
  },
  computed: {
    fieldsTabClass() { return this.activeTab === 'fields' ? 'text-selected' : 'text' },
    rulesTabClass() { return this.activeTab === 'rules' ? 'text-selected' : 'text' },
    detailsTabClass() { return this.activeTab === 'details' ? 'text-selected' : 'text' },
  },
  methods: {
    emitDirty() {
      this.$emit('emitDirty')
    },
    updateActiveTab(newTab) {
      this.activeTab = newTab
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.view-body {
  height: calc(100svh - 56px);
  flex: 1 1 auto;
  padding: 16px;
  overflow-y: auto;
}

.tab-container {
  background-color: #FFFFFF;
  width: 100%;
  overflow-y: auto;
  margin: auto;
  max-height: calc(100svh - 90px);

  display: flex;
  flex-flow: column;
  flex: 1;

  border-radius: 0.5rem;
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(33, 37, 41, 0.075);
  box-shadow: 0 0.125rem 0.25rem rgba(33, 37, 41, 0.075);
  background-color: #ffffff;
}

.tab-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0 1px 0 0 #e4e5e6;
  box-shadow: 0 1px 0 0 #e4e5e6;
}

.tab-content {
  min-height: 30svh;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow-y: auto;
  padding: 8px;
  margin: 4px;
  position: relative;
}

::v-deep .tab-content div.container {
  width: 100%;
}

// sm: 576px
@media (min-width: $breakpoint-sm) {
  .tab-container {
    width: 540px;
  }
}

// md: 768px
@media (min-width: $breakpoint-md) {
  .tab-container {
    width: 720px;
  }

  ::v-deep .tab-content div.container {
    width: 90%;
  }
}

// lg: 992px
@media (min-width: $breakpoint-lg) {
  .tab-container {
    width: 960px;
  }

  ::v-deep .tab-content div.container {
    width: 700px;
  }
}

// xl: 1200px
@media (min-width: $breakpoint-xl) {
  .tab-container {
    width: 1140px;
  }
}

// xxl: 1400px
@media (min-width: $breakpoint-xxl) {
  .tab-container {
    width: 1320px;
  }
}
</style>
