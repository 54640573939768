<template>
  <div class="container">
    <table class="table-rules">
      <thead>
        <tr>
          <th>
            <label class="vasion-page-subheader">Field</label>
          </th>
          <th>
            <label class="vasion-page-subheader">Comparison</label>
          </th>
          <th>
            <label class="vasion-page-subheader">Value</label>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in rules" :key="index">
          <td>
            <VasionDropList
              v-slot="slotItem"
              v-model="item.selectedField"
              displayName="name"
              title=""
              type="plain-list"
              valueName="value"
              width="100%"
              :closeOnOutsideClick="true"
              :dataArray="filteredFieldsData"
              :overlayList="true"
              @input="emitDirty(); buildRulesData();"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </td>
          <td>
            <VasionDropList
              v-slot="slotItem"
              v-model="item.selectedOperator"
              displayName="name"
              title=""
              type="plain-list"
              valueName="value"
              width="130"
              :closeOnOutsideClick="true"
              :dataArray="operatorData"
              :isDisabled="true"
              :overlayList="true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </td>
          <td>
            <VasionInput
              v-model="item.value"
              inputType="top-white"
              title=""
              :isInErrorState="false"
              :maxLengthProp="50"
              @input="emitDirty(); buildRulesData();"
            />
          </td>
          <td>
            <VasionButton
              title="Remove Field"
              :icon="'delete'"
              :isDense="true"
              @vasionButtonClicked="deleteRule(index); buildRulesData();"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <VasionButton
      id="btnAddRule"
      :classProp="'secondary'"
      @vasionButtonClicked="addRule()"
    >
      Add Rule
    </VasionButton>
  </div>
</template>
<script>
export default {
  name: 'PackageTabRules',
  data: function () {
    return {
      fieldsData: {
        rules: [],
      },
      filteredFieldsData: [],
      objectFields: [],
      operatorData: [
        {
          value: 1,
          name: 'Equals',
        },
      ],
      rules: [],
    }
  },
  computed: {
    formID() { return this.$store.state.forms.formID },
    pkgToEdit() { return this.$store.state.forms.pkgToEdit },
  },
  watch: {
    formID: async function (newFormID) {
      if (!(typeof newFormID === 'number')) return
      if (newFormID <= 0) return
      await this.initializeData(newFormID)
      await this.setValuesToEdit(this.pkgToEdit)
    }
  },
  async deactivated() { await this.updateStore() },
  async created() {
    if (!this.formID) return
    await this.initializeData(this.formID)
    await this.setValuesToEdit(this.pkgToEdit)
  },
  methods: {
    async addRule(rule = null) {
      if (!rule) {
        let emptyRule = false
        this.rules.map((rule) => {
          if (!(rule.selectedField?.fieldID && rule.selectedOperator?.value && rule.value)) {
            emptyRule = true
          }
        })
        if (emptyRule) return

        this.rules.push({
          selectedField: {},
          selectedOperator: this.operatorData[0],
          value: '',
        })
        return
      }

      const selectedField = await this.filteredFieldsData.filter((field) => {
        return rule.FieldId === field.fieldID
      })[0]

      const selectedOperator = await this.operatorData.filter((operator) => {
        return rule.Operator === operator.value
      })[0]

      this.rules.push({
        selectedField: selectedField,
        selectedOperator: selectedOperator,
        value: rule.Value,
      })
      this.buildRulesData()
    },
    async buildRulesData() {
      this.fieldsData.rules = this.rules.map((rule) => {
        if (rule.selectedField?.fieldID && rule.selectedOperator?.value && rule.value) {
          return {
            FieldId: rule.selectedField.fieldID,
            Operator: rule.selectedOperator.value,
            Value: rule.value,
          }
        }
      }).filter(rule => rule)
      this.updateStore()
    },
    deleteRule(index) {
      this.rules.splice(index, 1)
    },
    emitDirty() {
      this.$emit('emitDirty')
    },
    getFilteredObjectFields(fields) {
      return fields.filter((field) => {
        return field.type !== 3
      })
    },
    async initializeData(formID) {
      if (this.$store.state.common.indexFields?.length < 1)
      await this.$store.dispatch('common/getIndexFieldsForForm', formID)

      this.objectFields = this.$store.state.common.indexFields
      this.filteredFieldsData = await this.getFilteredObjectFields(this.objectFields)
    },
    async setValuesToEdit(pkg) {
      if (Object.keys(pkg).length === 0) {
        this.addRule()
        return
      }
      const rules = pkg.PackageRules
      if (!rules) {
        this.addRule()
        return
      }
      if (rules.length <= 0) {
        this.addRule()
        return
      }
      await rules.forEach(rule => {
        this.addRule(rule)
      });
    },
    async updateStore() {
      await this.$store.dispatch('forms/setPkgRules', this.fieldsData)
    },
  },
}
</script>
<style lang="scss" scoped>
table.table-rules {
  width: 100%;
  border-collapse: separate;
  border-spacing: 8px;
  thead>tr>th:nth-child(1) {
    width: 226px;
  }
  thead>tr>th:nth-child(2) {
    width: 134px;
  }
  thead>tr>th:nth-child(3) {
    width: 202px;
  }
  thead>tr>th:nth-child(4) {
    width: 70px;
  }
}
</style>
