<template>
  <div>
    <Loading
      :active.sync="showOverlay"
      :is-full-page="true"
      :color="'#FFFFFF00'"
      :background-color="'#FFFFFF'"
      :opacity="1"
      :lock-scroll="true"
    />
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
      :opacity="1"
    />
    <div class="view-container">
      <PackageHeader
        :title="isEditMode ? 'EDIT PACKAGE' : 'NEW PACKAGE'"
        :saveAvailable="true"
        :cancel="cancelSavePackage"
        :save="savePackage"
      />
      <PackageBody
        ref="packageBody"
        @emitDirty="emitDirty"
      />
    </div>
    <VasionSnackbar
      :showRawHtml="snackbar.showRawHtml" 
      :showSnackbarBool.sync="snackbar.show"
      :snackbarImage="snackbar.image"
      :snackbarSubTitle="snackbar.subtitle"
      :snackbarTitle="snackbar.title"
    />
    <md-dialog
      id="modal-exit-prevention"
      :md-active.sync="showPreventExitDialog"
      :md-click-outside-to-close="false"
    >
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="showPreventExitDialog = false"
        @yesButtonClick="cancelSavePackage(false)"
      />
    </md-dialog>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay';
import PackageHeader from '@/components/forms/package/PackageHeader.vue';
import PackageBody from '@/components/forms/package/PackageBody.vue';

import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'FormPackage',
  components: {
    Loading,
    PackageHeader,
    PackageBody,
  },
  beforeRouteLeave: async function (to, from, next) {
    await this.$store.dispatch('forms/setFormID', null)
    await this.$store.dispatch('forms/setPkgDetails', {})
    await this.$store.dispatch('forms/setPkgFields', {})
    await this.$store.dispatch('forms/setPkgFieldsErrors', {})
    await this.$store.dispatch('forms/setPkgRules', {})
    await this.$store.dispatch('forms/setPkgToEdit', {})
    this.showOverlay = true
    await this.$store.dispatch('forms/setDefaultFormTab', 'packages')
    setTimeout(() => {
        this.$store.dispatch('common/hideNavbarAndHeader', false)
        this.$store.dispatch('common/setMainContainerWithFullWidth', false)
      }, 300)
    next();
  },
  data: function () {
    return {
      formID: Number(this.$route.params.formID),
      isDirty: false,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      packageId: this.$route.params.packageId,
      packageToEdit: {},
      showOverlay: false,
      showPreventExitDialog: false,
      snackbar: {
        image: false,
        show: false,
        showRawHtml: true,
        subtitle: '',
        title: '',
      },
    }
  },
  computed: {
    isEditMode() {
      return this.packageId ? true : false
    },
    pkgDetails() { return this.$store.state.forms.pkgDetails },
    pkgFields() { return this.$store.state.forms.pkgFields },
    pkgFieldsErrors() { return this.$store.state.forms.pkgFieldsErrors },
    pkgRules() { return this.$store.state.forms.pkgRules },
  },
  created: async function () {
    this.isLoading = true
    if (this.isEditMode) {
      this.packageToEdit = await this.getPackageToEdit(this.packageId)
      await this.updateStore(this.packageToEdit)
    }
    await this.$store.dispatch('forms/setFormID', this.formID)
    this.isLoading = false
  },
  methods: {
    async cancelSavePackage(checkDirty = true) {
      if (this.isDirty && checkDirty) {
        this.showPreventExitDialog = true
        return
      }
      this.$router.push({ name: 'EditForm', params: { formID: this.$route.params.formID } })
    },
    emitDirty() {
      this.isDirty = true
    },
    async getPackageToEdit(packageId) {
      const response = await this.$store.dispatch('forms/getPackager', { Value: packageId})
      if (response && response?.ResultStatus === 1) {
        return response.package
      } else {
        this.snackbar.image = false
        this.snackbar.show = true
        this.snackbar.subtitle = response?.ResultMessage ? response.ResultMessage : 'Error while getting Packager Configuration'
        this.snackbar.title = 'ERROR'
        setTimeout(() => { this.$router.push({ name: 'EditForm', params: { formID: this.$route.params.formID } }) }, 1000);
        return false
      }
    },
    async savePackage() {
      if (!this.isFormFieldsValid()) return
      if (!this.isDetailsValid()) return
      this.isLoading = true
      const packageFieldsData = this.pkgFields
      const packageRulesData = this.pkgRules
      const packageDetails = this.pkgDetails

      const payload = {
        "PackageID": this.isEditMode ? this.packageId : 0,
        "Name": packageFieldsData.name,
        "FormId": this.formID,
        "IsDisabled": this.packageToEdit?.IsDisabled ? this.packageToEdit.IsDisabled : false,
        "LinkFieldIds": packageFieldsData.linkFieldIds,
        "PackageField": packageFieldsData.packageField,
        "PackageFieldValues": packageFieldsData.packageFieldValues,
        "PackageRules": packageRulesData.rules,
        "MergeDocuments": packageDetails.mergeDocuments,
        "MergeFolder": packageDetails.mergeFolder,
        "MergeWorkflow": packageDetails.mergeWorkflow,
        "MovePackage": packageDetails.movePackage,
        "MoveWorkflow": packageDetails.moveWorkflow,
        "MoveFolder": packageDetails.moveFolder,
        "AutoSubmit": packageDetails.autoSubmit,
      }
      
      const response = await this.$store.dispatch('forms/savePackager', payload)
      
      this.isLoading = false
      if (response && response?.ResultStatus === 1) {
        this.snackbar.image = true
        this.snackbar.show = true
        this.snackbar.subtitle = 'Packager configuration saved successfully'
        this.snackbar.title = 'Success!'
        setTimeout(() => { this.$router.push({ name: 'EditForm', params: { formID: this.$route.params.formID } }) }, 1000);
      } else {
        this.snackbar.image = false
        this.snackbar.show = true
        this.snackbar.subtitle = response?.ResultMessage ? response.ResultMessage : 'Error while saving Packager Configuration'
        this.snackbar.title = 'ERROR'
      }
    },
    isDetailsValid() {
      const pkgDetails = this.pkgDetails
      let errors = []
      if (pkgDetails.mergeDocuments) {
        if (!pkgDetails.mergeFolder && !pkgDetails.mergeWorkflow) {
          errors.push("You must select either a folder or a workflow to merge to.")
        }
      }
      if (pkgDetails.movePackage) {
        if (!pkgDetails.moveFolder && !pkgDetails.moveWorkflow) {
          errors.push("You must select either a folder or workflow to move package documents to.")
        }
      }
      if (!errors?.length) return true
      let errorsMessage = ''
      for (let index = 0; index < errors.length; index++) {
        const error = errors[index];
        errorsMessage += `<li>- ${error}</li>`
      }
      const subtitle = `<ul class="vasion-ul" style="width: 500px; position: relative; right: 7px">${errorsMessage}</ul>`

      this.snackbar.image = false
      this.snackbar.show = true
      this.snackbar.subtitle = subtitle
      this.snackbar.title = 'ERROR: Missing details'
      
      return false
    },
    isFormFieldsValid() {
      const packageTabFieldsErrors = this.pkgFieldsErrors
      if (!packageTabFieldsErrors?.length) return true
      const errors = [...packageTabFieldsErrors]
      // Error Message construction
      let errorsMessage = ''
      for (let index = 0; index < errors.length; index++) {
        const error = errors[index];
        errorsMessage += `<li>- ${error}</li>`
      }
      const subtitle = `<ul class="vasion-ul" style="width: 500px; position: relative; right: 7px">${errorsMessage}</ul>`

      this.snackbar.image = false
      this.snackbar.show = true
      this.snackbar.subtitle = subtitle
      this.snackbar.title = 'ERROR: Missing fields'
      
      return false
    },
    async updateStore(packageToEdit) {
      const packageFieldValues = await packageToEdit.PackageFieldValues.map((row) => {
        return {
          "DoNotMerge": row.DoNotMerge,
          "MergeOrder": row.MergeOrder,
          "Required": row.Required,
          "Value": row.Value,
        }
      })
      packageToEdit.PackageFieldValues = packageFieldValues
      await this.$store.dispatch('forms/setPkgToEdit', packageToEdit)

      const fields = {
        name: packageToEdit.Name,
        linkFieldIds: packageToEdit.LinkFieldIds,
        packageField: packageToEdit.PackageField,
        packageFieldValues: packageToEdit.PackageFieldValues,
      }
      const rules = {
        rules: packageToEdit.PackageRules,
      }
      const details = {
        mergeDocuments: packageToEdit.MergeDocuments,
        mergeFolder: packageToEdit.MergeFolder,
        mergeWorkflow: packageToEdit.MergeWorkflow,
        movePackage: packageToEdit.MovePackage,
        moveWorkflow: packageToEdit.MoveWorkflow,
        moveFolder: packageToEdit.MoveFolder,
        autoSubmit: packageToEdit.AutoSubmit,
      }
      await this.$store.dispatch('forms/setPkgDetails', details)
      await this.$store.dispatch('forms/setPkgFields', fields)
      await this.$store.dispatch('forms/setPkgFieldsErrors', [])
      await this.$store.dispatch('forms/setPkgRules', rules)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.view-container {
  max-height: 100svh;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100svh;
  margin: 0;
  overflow-y: auto;
  background-color: #F5F4F7;
}
</style>
