<template>
  <div class="container">
    <Loading
      :active.sync="isLoading"
      :background-color="loaderBackgroundColor"
      :color="loaderColor"
      :is-full-page="false"
      :opacity="1"
      loader="dots"
    />
    <VasionInput
      v-model="fieldsData.name"
      inputType="top-white"
      title="Package Name"
      required
      :isInErrorState="false"
      :maxLengthProp="50"
      type="text"
      @input="emitDirty(); updateStore();"
    />
    <VasionDropList
      v-slot="slotItem"
      v-model="packageLinkFields"
      class="field-mt"
      displayName="name"
      title="Link Fields"
      type="check-list"
      valueName="value"
      width="100%"
      :closeOnOutsideClick="true"
      :dataArray="packageLinkFieldsData"
      :overlayList="packageField ? false : true"
      :pillOptions="true"
      @input="emitDirty(); removeSeleteFromDropdownLists(); updateStore();"
    >
      {{ slotItem.item.name }}
    </VasionDropList>
    <div v-if="packageFieldData.length === 0">
      <p class="message">
        No dropdown fields found
      </p>
    </div>
    <VasionDropList
      v-else
      v-slot="slotItem"
      v-model="packageField"
      class="field-mt"
      displayName="name"
      title="Package Field"
      type="plain-list"
      valueName="value"
      width="100%"
      :closeOnOutsideClick="true"
      :dataArray="packageFieldData"
      :overlayList="packageField ? false : true"
      @input="emitDirty(); removeSeleteFromDropdownLists(); setDefinePackageTable($event); updateStore();"
    >
      {{ slotItem.item.name }}
    </VasionDropList>
    <VasionEditableTable
      v-if="packageField"
      class="table"
      :fixedColumnsSizes="true"
      :headerColumns="headerColumns"
      :rowHoverPointer="false"
      :tableRows="tableRows"
      @cellValueChanged="cellValueChanged"
    />
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import { find } from 'lodash'

export default {
  name: 'PackageTabFields',
  components: {
    Loading,
  },
  data: function () {
    return {
      errorMessages: {
        linkFieldIds: 'At least one option must be selected for Link Fields.',
        name: 'The Package Name cannot be empty.',
        packageField: 'An option must be selected for Package Field.',
        packageFieldValues: 'At least one field must be included in the Package Definition Table.',
      },
      fieldsData: {
        linkFieldIds: [],
        name: '',
        packageField: '',
        packageFieldValues: [],
      },
      headerColumns: [],
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      objectDropdownFields: [],
      objectFields: [],
      packageField: '',
      packageFieldData: [],
      packageLinkFields: [],
      packageLinkFieldsData: [],
      tableRows: [],
    }
  },
  computed: {
    fieldsDataValid() {
      return this.fieldsData.name !== "" && this.fieldsData.linkFieldIds.length > 0 && typeof this.fieldsData.packageField === 'number' && this.fieldsData.packageFieldValues.length > 0
    },
    formID() { return this.$store.state.forms.formID },
    errors() {
      const isPackageNameValid = this.fieldsData.name !== ""
      const isLinkFieldIdsValid = this.fieldsData.linkFieldIds.length > 0
      const isPackageFieldValid = typeof this.fieldsData.packageField === 'number'
      const isPackageFieldValuesValid = this.fieldsData.packageFieldValues.length > 0
      let errors = []
      if (!isPackageNameValid) errors.push(this.errorMessages.name)
      if (!isLinkFieldIdsValid) errors.push(this.errorMessages.linkFieldIds)
      if (!isPackageFieldValid) errors.push(this.errorMessages.packageField)
      if (!isPackageFieldValuesValid) errors.push(this.errorMessages.packageFieldValues)
      return errors
    },
    pkgFieldValues() { return this.$store.state.forms.pkgFields.packageFieldValues },
    pkgToEdit() { return this.$store.state.forms.pkgToEdit },
  },
  watch: {
    packageField: async function (newPackageField) {
      this.fieldsData.packageField = newPackageField.fieldID
    },
    packageLinkFields: async function (newPackageLinkFields) {
      this.fieldsData.linkFieldIds = await newPackageLinkFields.map((field) => {
        return field.fieldID
      })
    },
    pkgFieldValues: async function (newPkgFields) {
      if (!newPkgFields) return

      const includeColIndex = 1, requiredColIndex = 2
      for (let index = 0; index < this.tableRows.length; index++) {
        const row = this.tableRows[index];
        const valueToSearch = row.Cells[0].cellData
        const elementFounded = find(newPkgFields, ['Value', valueToSearch])
        if (!elementFounded) {
          this.tableRows[index].Cells[includeColIndex].cellData = false
          this.tableRows[index].Cells[requiredColIndex].isDisabled = true
          this.tableRows[index].Cells[requiredColIndex].cellData = false
          this.tableRows[index].Cells[0].MergeOrder = elementFounded?.MergeOrder ? elementFounded.MergeOrder : index
          this.tableRows[index].Cells[0].DoNotMerge = elementFounded?.DoNotMerge ? elementFounded.DoNotMerge : false
        }
      }
    },
    formID: async function (newFormID) {
      if (!(typeof newFormID === 'number')) return
      if (newFormID <= 0) return
      this.isLoading = true
      await this.initializeData(newFormID)
      await this.setValuesToEdit(this.pkgToEdit)
      this.isLoading = false
    },
  },
  async created() {
    this.headerColumns = [
      { title: 'Defined Value', sortable: false, type: -1, columnWidth: '60%' },
      { title: 'Include', sortable: false, type: 9, textAlign: 'center' },
      { title: 'Required', sortable: false, type: 9, textAlign: 'center' },
    ]
    if (!this.formID) return
    this.isLoading = true
    await this.initializeData(this.formID)
    this.setValuesToEdit(this.pkgToEdit)
    this.isLoading = false
  },
  methods: {
    async cellValueChanged({ rowData, rowIndex, columnIndex }) {
      const includeColIndex = 1, requiredColIndex = 2
      if (columnIndex === includeColIndex) {
        // Set include value
        const newIncludeVal = rowData.Cells[columnIndex].cellData
        this.tableRows[rowIndex].Cells[includeColIndex].cellData = newIncludeVal
        // Enable/disable required column
        this.tableRows[rowIndex].Cells[requiredColIndex].isDisabled = newIncludeVal ? false : true
        if (!newIncludeVal) this.tableRows[rowIndex].Cells[requiredColIndex].cellData = false
      }
      if (columnIndex === requiredColIndex) {
        // Set required value
        const newRequiredVal = rowData.Cells[columnIndex].cellData
        this.tableRows[rowIndex].Cells[requiredColIndex].cellData = newRequiredVal
      }
      this.fieldsData.packageFieldValues = await this.updatePackageFieldValues(this.tableRows)
      await this.updateStore()
    },
    emitDirty() {
      this.$emit('emitDirty')
    },
    async getObjectDropdownFields(fields, itemsToIgnore = []) {
      return fields.filter((field) => {
        return field.type === 2 && !itemsToIgnore.includes(field.value)
      })
    },
    async getObjectFields(fields, itemsToIgnore = []) {
      return fields.filter((field) => {
        return !itemsToIgnore.includes(field.value)
      })
    },
    async initializeData(formID) {
      await this.$store.dispatch('common/getIndexFieldsForForm', formID)
      this.objectFields = this.$store.state.common.indexFields
      this.packageLinkFieldsData = await this.getObjectFields(this.objectFields)
      this.objectDropdownFields = await this.getObjectDropdownFields(this.objectFields)
      this.packageFieldData = this.objectDropdownFields

      const error = Object.keys(this.errorMessages).map((key) => this.errorMessages[key])
      await this.$store.dispatch('forms/setPkgFieldsErrors', error)
    },
    async removeSeleteFromDropdownLists() {
      const linkFieldsToIgnore = await this.packageLinkFields.map((field) => {
        return field.value
      })
      const packageFieldToIgnore = this.packageField.value ? [this.packageField.value] : []

      this.packageLinkFieldsData = await this.getObjectFields(this.objectFields, packageFieldToIgnore)
      this.packageFieldData = await this.getObjectDropdownFields(this.objectFields, linkFieldsToIgnore)
    },
    async setDefinePackageTable(selectedDropdown) {
      this.fieldsData.packageFieldValues = []
      if (!selectedDropdown?.dropdownValues) return []
      this.tableRows = await selectedDropdown.dropdownValues.map((option) => {
        return {
          Cells: [
            {
              "cellData": option.name,
              "canEdit": false,
              "DoNotMerge": null,
              "MergeOrder": null,
            },
            {
              "cellData": false,
              "isDisabled": false,
            },
            {
              "cellData": false,
              "isDisabled": true,
            },
          ]
        }
      })
    },
    async setValuesToEdit(pkg) {
      if (Object.keys(pkg).length === 0) return

      this.fieldsData.name = pkg.Name
      this.packageLinkFields = await this.packageLinkFieldsData.filter((field) => {
        if (field?.fieldID) return pkg.LinkFieldIds.includes(field.fieldID)
      })
      this.packageField = await this.packageFieldData.filter((field) => {
        return pkg.PackageField === field.fieldID
      })[0]

      await this.removeSeleteFromDropdownLists()
      await this.setDefinePackageTable(this.packageField)

      const includeColIndex = 1, requiredColIndex = 2
      for (let index = 0; index < this.tableRows.length; index++) {
        const row = this.tableRows[index];
        const valueToSearch = row.Cells[0].cellData
        const elementFounded = find(pkg.PackageFieldValues, ['Value', valueToSearch])
        if (elementFounded) {
          this.tableRows[index].Cells[includeColIndex].cellData = true
          this.tableRows[index].Cells[requiredColIndex].isDisabled = false
          this.tableRows[index].Cells[requiredColIndex].cellData = elementFounded.Required
          this.tableRows[index].Cells[0].MergeOrder = elementFounded.MergeOrder
          this.tableRows[index].Cells[0].DoNotMerge = elementFounded.DoNotMerge
        }
      }

      this.fieldsData.packageFieldValues = await this.updatePackageFieldValues(this.tableRows, false)

      await this.$store.dispatch('forms/setPkgFieldsErrors', [])
    },
    async updatePackageFieldValues(newRows, emitDirty = true) {
      const rows = await newRows.map((row) => {
        if (row.Cells[1].cellData) {
          return {
            "DoNotMerge": row.Cells[0].DoNotMerge,
            "MergeOrder": row.Cells[0].MergeOrder,
            "Required": row.Cells[2].cellData,
            "Value": row.Cells[0].cellData,
          }
        }
      }).filter(obj => obj)

      if (emitDirty) this.emitDirty()

      return rows
    },
    async updateStore() {
      await this.$store.dispatch('forms/setPkgFields', this.fieldsData)
      await this.$store.dispatch('forms/setPkgFieldsErrors', this.errors)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.field-mt {
  margin-top: 8px;
}

.message {
  font-family: Archivo;
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #161718;
  margin: 0;
  text-align: center;
  margin-top: 16px;
}

.table {
  height: calc(100vh - 460px);
  overflow-y: auto;
  margin-top: 16px;
}

.table ::v-deep .table-rows:hover {
  background-color: #e7e7e7;
}
</style>